<template>
    <div class="alert alert-warning">{{ $t(messageI18n) }}</div>
</template>

<script type="text/javascript">
	export default {
        props:[
            'messageI18n',
        ]
	}
</script>
